<template>
  <div
    class="c-input"
    :class="{...wrapperClass, 'has-invalid-feedback': hasErrors}"
  >
    <div v-show="showConditionalField" class="c-input__inner">
      <label
        class="form-label c-input__label"
        :class="{required, 'visually-hidden': hideLabel, ...labelClass}"
        :for="fieldId"
        v-html="fieldLabel"
      />
      <uppy-drag-drop
        v-bind="dragDropProps"
        :id="fieldId"
        ref="input"
        :uppy="uppy"
        :class="inputClass"
        class="c-input__input"
      />
      <div v-if="hasErrors" class="text-danger my-3 p-3 border rounded border-danger">
        <h5 class="mb-3">
          <svg class="c-icon c-icon--error">
            <use href="#error" />
          </svg>
          Import Errors
        </h5>
        <div class="o-section o-section--flush o-section--dense mb-5">
          <div>{{ importErrorText }}</div>
          <ul v-if="uploadErrors && uploadErrors.length > 0">
            <li v-for="error in uploadErrors" :key="error">
              {{ error }}
            </li>
          </ul>
          <div v-if="sheetsWithErrors && sheetsWithErrors.length > 0">
            <div>The following worksheets contain errors:</div>
            <ul>
              <li v-for="sheet in sheetsWithErrors" :key="sheet">
                {{ sheet }}
              </li>
            </ul>
            <div v-if="fileUrl">
              To view and correct these errors. Please download the file annotated with errors below.
            </div>
          </div>
        </div>
        <a v-if="fileUrl" class="btn btn-primary" :href="fileUrl" target="_blank">
          Download file with errors
        </a>
      </div>
    </div>

    <c-modal
      v-if="showModal"
      ref="modal"
      is-scrollable
      disable-click-outside
      @close="cancel"
    >
      <template #title>
        {{ progressTitle }}
      </template>
      <template #body>
        <div class="p-4">
          <progress-bar-card :percentage="progress" :description="dynamicDescription" />
        </div>
      </template>
      <template #footer>
        <button
          type="button"
          class="btn btn-naked"
          @click="cancel"
        >
          Cancel
        </button>
      </template>
    </c-modal>
  </div>
</template>

<script>
import formInput from '@/js/mixins/formInput.js';
import fileInput from '@/js/mixins/file_input.js';
import conditionalField from '@/js/mixins/conditionalField.js';
import UppyDragDrop from '@canopyllc/roots/components/UppyDragDrop.vue';
import {error as errorPrompt, success} from '@/js/utils/sweetAlert2.js';
import CModal from '@/js/components/CModal.vue';
import ProgressBarCard from '@/js/components/ProgressBarCard.vue';
import {taskStatus, alertMessages} from '@/js/enums/api.js';
import {useBackgroundTask} from '@/js/composables/backgroundTask.js';

/**
* @see UppyDragDrop for the props allowed
*/
export default {
  name: 'UploadImportFileInput',
  components: {ProgressBarCard, CModal, UppyDragDrop},
  mixins: [formInput, fileInput, conditionalField],
  inject: ['csrfToken', 'urls'],
  props: {
    // Also you can pass any props that belong to UppyDragDrop component
    // width, height, note, locale, thumbnailWidth, thumbnailHeight, waitForThumbnailsBeforeUpload
    dragDropProps: {
      type: Object,
      default: () => ({}),
    },
    additionalImportUploadParams: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    progressTitle: {
      type: String,
      default: 'Starting Background Task',
    },
    progressDescription: {
      type: String,
      default: 'Importing file...',
    },
  },
  setup() {
    const {
      progress, poll, cancel, status, setTaskId, lastResponse, description,
    } = useBackgroundTask();

    return {
      progress, poll, cancel, status, setTaskId, lastResponse, description,
    };
  },
  data() {
    return {
      showModal: false,
      uploadErrors: [],
      sheetsWithErrors: [],
      hasErrors: false, // this is needed since the file can be in error and uploadErrors can be empty
      fileUrl: '',
      importErrorText: 'There were errors when attempting to import the template file.',
    };
  },
  computed: {
    dynamicDescription() {
      return this.description || this.progressDescription;
    },
  },
  watch: {
    async status() {
      if (this.status === taskStatus.SUCCESS) {
        this.showModal = false;
        await success({title: alertMessages.UPLOAD_SUCCESS, text: 'Your data was successfully imported!'});
        window.location.reload();
      } else if (this.status === taskStatus.CANCELED) {
        this.showModal = false;
      } else if (this.status === taskStatus.FAILURE) {
        this.showModal = false;
        this.uploadErrors = ['An unknown error occurred. Please contact support.'];
        if (this.lastResponse.import_errors) {
          this.uploadErrors = this.lastResponse.import_errors;
        }
        if (this.lastResponse.sheet_errors) {
          this.sheetsWithErrors = Object.keys(this.lastResponse.sheet_errors).filter((key) => this.lastResponse.sheet_errors[key].length > 0);
        }
        this.fileUrl = this.lastResponse.error_workbook_link;
        this.hasErrors = true;
        if (this.fileUrl) {
          errorPrompt({
            text: this.importErrorText,
            confirmButtonText: 'Download file with errors',
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              const a = document.createElement('a');
              a.href = this.fileUrl;
              a.download = 'errors.xlsx';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          });
        } else {
          errorPrompt({text: this.importErrorText});
        }
      }
    },
  },
  mounted() {
    this.uppy
      .on('upload-error', () => {
        this.showModal = false;
        // failure at this point is the result of creation of upload or push to S3 failure
        // so we probably don't have a excel doc with errors
        errorPrompt({text: alertMessages.UPLOAD_FAILURE});
      })
      .on('file-added', () => {
        this.resetErrors();
      });
  },
  methods: {
    resetErrors() {
      this.uploadErrors = [];
      this.hasErrors = false;
      this.fileUrl = '';
    },
    /**
     * onComplete handler is called after:
     * 1. POST to presigned post url is successful
     * 2. POST to Upload endpoint to CREATE upload is successful
     * 3. File is successfully uploaded to S3
     *
     * @override uppy.js onComplete handler
     */
    async onComplete({failed, successful}) {
      if (failed.length > 0) {
        this.showModal = false;
        errorPrompt({text: 'File upload failed. Please try again.'});
      }
      if (successful.length === 1) { // assumes 1 file uploaded at a time
        try {
          const {data} = await this.api.post(this.urls.importUploadListUrl, {
            ...this.additionalImportUploadParams,
            import_file: successful[0].meta.uploadId,
          });
          this.showModal = true;
          await this.poll(data.task_id);
        } catch (error) {
          // This is primarily to catch failure on importUploadListUrl,
          // but would also catch failure of taskProgressUrl.
          // We may need to have some retry functionality in the future if the progress endpoint fails
          // because its possible for it to fail and it not mean the job failed
          this.showModal = false; // necessary if taskProgressUrl url failed
          errorPrompt({text: error.message || error});
        }
      }

      // clear Uppy - are not keeping record of files inside uppy
      [...failed, ...successful].forEach((file) => {
        this.uppy.removeFile(file.id);
      });
    },
  },
};
</script>
